import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _697508a2 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _4deb7f52 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2e9bc055 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _58a399bb = () => interopDefault(import('../pages/search/documents.vue' /* webpackChunkName: "pages/search/documents" */))
const _0bae4831 = () => interopDefault(import('../pages/search/products.vue' /* webpackChunkName: "pages/search/products" */))
const _379a05d0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _67be675a = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _d81cf908 = () => interopDefault(import('../pages/documents/_slug/index.vue' /* webpackChunkName: "pages/documents/_slug/index" */))
const _50fe46f8 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _15797ea0 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _3b074860 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/catalog",
    component: _697508a2,
    name: "catalog___uk"
  }, {
    path: "/search",
    component: _4deb7f52,
    children: [{
      path: "",
      component: _2e9bc055,
      name: "search___uk"
    }, {
      path: "documents",
      component: _58a399bb,
      name: "search-documents___uk"
    }, {
      path: "products",
      component: _0bae4831,
      name: "search-products___uk"
    }]
  }, {
    path: "/",
    component: _379a05d0,
    name: "index___uk"
  }, {
    path: "/catalog/:slug",
    component: _67be675a,
    name: "catalog-slug___uk"
  }, {
    path: "/documents/:slug",
    component: _d81cf908,
    name: "documents-slug___uk"
  }, {
    path: "/news/:slug?",
    component: _50fe46f8,
    name: "news-slug___uk"
  }, {
    path: "/product/:slug?",
    component: _15797ea0,
    name: "product-slug___uk"
  }, {
    path: "/:slug",
    component: _3b074860,
    name: "slug___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
