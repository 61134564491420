import { render, staticRenderFns } from "./SolutionsCarouselSlider.vue?vue&type=template&id=2b18ff42"
import script from "./SolutionsCarouselSlider.vue?vue&type=script&setup=true&lang=ts"
export * from "./SolutionsCarouselSlider.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Rollback_Prod@2/components/ui/UiIcon.vue').default,SolutionsCarouselSlide: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Rollback_Prod@2/components/sections/sliderSection/SolutionsCarouselSlide.vue').default})
