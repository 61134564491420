import { render, staticRenderFns } from "./ProductVideo.vue?vue&type=template&id=7e49e017&scoped=true"
import script from "./ProductVideo.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductVideo.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProductVideo.vue?vue&type=style&index=0&id=7e49e017&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e49e017",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Rollback_Prod@2/components/ui/UiIcon.vue').default})
