import { render, staticRenderFns } from "./AppLogo.vue?vue&type=template&id=0b2be911"
import script from "./AppLogo.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppLogo.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Rollback_Prod@2/components/ui/UiIcon.vue').default})
